import { NgModule } from '@angular/core';

import { EmailVerificationComponent } from './email-verification.component';
import { EmailVerificationService }   from './email-verification.service';
import { SharedModule }               from '../shared/shared.module';

@NgModule({
  declarations: [
    EmailVerificationComponent
  ],
  entryComponents: [
    EmailVerificationComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    EmailVerificationService
  ]
})
export class EmailVerificationModule {}