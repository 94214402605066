import { CompanyCountryValidationsModel } from '../../companies/models/company.country-validations';
import { SessionServiceAjs }              from '../../sessions/session.service.ajs';

interface PhoneValidatorScope extends ng.IScope {
  defaultValidation   : string;
  formValidityKey     : string;
  getAndSetValidations: () => void;
  isMobile            : boolean;
  mask                : string;
  regex               : RegExp;
  validations         : CompanyCountryValidationsModel;
};


export function PhoneValidatorDirective (
  sessionObjAPI: SessionServiceAjs
) : ng.IDirective {
  const directive : ng.IDirective = {
    require: 'ngModel',
    scope  : {
      isMobile   : '<?',
      validations: '=?'
    },
    link (
      scope  : PhoneValidatorScope,
      element: ng.IRootElementService,
      attrs  : ng.IAttributes,
      ctrl   : ng.INgModelController
    ) {
      scope.defaultValidation = '^\\d+$';
      scope.formValidityKey   = 'phone';

      scope.getAndSetValidations = function () {
        const countryValidations = sessionObjAPI.countryValidation();
        let regex;

        if (scope.validations) {
          scope.mask = scope.isMobile && scope.validations.mobile?.mask
            ? scope.validations.mobile.mask
            : scope.validations.phone?.mask;

          regex = scope.isMobile && scope.validations.mobile?.validation
            ? scope.validations.mobile.validation
            : scope.validations.phone?.validation;
        }
        else {
          scope.mask = scope.isMobile && countryValidations.mobile?.mask
            ? countryValidations.mobile.mask
            : countryValidations.phone.mask;
          regex = scope.isMobile && countryValidations.mobile?.validation
            ? countryValidations.mobile.validation
            : countryValidations.phone?.validation;
        }

        scope.regex = new RegExp(regex || scope.defaultValidation);
      };

      ctrl.$parsers.push(( value : string ) => {
        scope.getAndSetValidations();

        if (!value) {
          ctrl.$setValidity(scope.formValidityKey, true);

          return '';
        }

        if (value.match(scope.regex)) {
          if (scope.mask) {
            ctrl.$setViewValue(value.replace(scope.regex, scope.mask));
            ctrl.$setValidity(scope.formValidityKey, true);
            ctrl.$render();

            return value.replace(scope.regex, '$1$2$3');
          }

          ctrl.$setValidity(scope.formValidityKey, true);
          ctrl.$render();

          return value;
        }

        ctrl.$setValidity(scope.formValidityKey, false);

        return value;
      });

      ctrl.$formatters.push(( value : string ) => {
        scope.getAndSetValidations();

        if (!value) {
          return '';
        }

        if (value.match(scope.regex)) {
          return scope.mask
            ? value.replace(scope.regex, scope.mask)
            : value;
        }

        ctrl.$setViewValue(ctrl.$viewValue);
        ctrl.$setValidity(scope.formValidityKey, false);

        return value;
      });

      scope.$watch('validations', ( newValidations : CompanyCountryValidationsModel, oldValidations : CompanyCountryValidationsModel ) => {
        if (newValidations !== oldValidations) {
          angular.forEach(ctrl.$parsers, ( parser : (Function) ) => parser(ctrl.$modelValue));
        }
      }, true);
    }
  };

  return directive;
}
