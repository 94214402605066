import { BlockUIModule }           from 'ng-block-ui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule }            from '@angular/common';
import { FormsModule }             from '@angular/forms';
import { Injector, NgModule }      from '@angular/core';
import { NgbModalModule }          from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule }        from '@ng-bootstrap/ng-bootstrap';
import { NgPluralizeModule }       from 'ng-pluralize';
import { UIRouterUpgradeModule }   from '@uirouter/angular-hybrid';

import { ComponentsModule } from './components/components.module';
import { ControlsModule   } from './controls/controls.module';
import { PipesModule      } from './pipes/pipes.module';
import { TooltipDirective } from './directives/tooltip.component';
import { ValidatorsModule } from './validators/validators.module';

@NgModule({
  declarations: [
    // Directives
    TooltipDirective
  ],
  exports: [
    // Angular
    CommonModule,
    FormsModule,

    // Third Party
    BlockUIModule,
    NgbModalModule,
    NgbTooltipModule,
    UIRouterUpgradeModule,

     // Custom
    ComponentsModule,
    ControlsModule,
    PipesModule,
    ValidatorsModule,

    // Directives
    TooltipDirective
  ],
  imports: [
    // Angular
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,

    // Third Party
    BlockUIModule.forRoot(),
    NgbModalModule,
    NgbTooltipModule,
    NgPluralizeModule,
    UIRouterUpgradeModule,

    // Custom
    ComponentsModule,
    ControlsModule,
    PipesModule,
    ValidatorsModule
  ],
  providers: [
    {
      deps      : [ '$injector' ],
      provide   : '$rootScope',
      useFactory: ( $injector : Injector ) => $injector.get('$rootScope')
    },
    {
      deps      : [ '$injector' ],
      provide   : '$translate',
      useFactory: ( $injector : Injector ) => $injector.get('$translate')
    },
    {
      deps      : [ '$injector' ],
      provide   : 'Company',
      useFactory: ( $injector : Injector ) => $injector.get('Company')
    }
  ]
})
export class SharedModule {}

