export function QuickbooksCountryCtrl ($scope, $state, $stateParams, $window, countries, messages, QuickbooksAction) {
  $scope.countries        = countries;
  $scope.quickbooksAction = new QuickbooksAction($stateParams);

  $scope.goToCountry = function () {
    $scope.quickbooksAction.getSignupUrlFromCountry($scope.user.country)
    .then(url => {
      $window.location.assign(url);
    })
    .catch(err => {
      messages.error(err);
    });
  };
}
