import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewContainerRef
}                       from "@angular/core";
import { Subscription } from "rxjs";

import { DynamicTableComponentBindings } from "./types/dynamic-table-component-bindings";
import { TableColumn }                   from './types/table-column';

@Component({
  selector   : 'wor-dynamic-table-cell, wor-dynamic-table-header, wor-dynamic-table-row, wor-dynamic-table-row-expanded',
  template: require('./dynamic-table-component.component.html')
})
export class DynamicTableComponentComponent implements OnInit, OnDestroy {
  @Input() bindings : DynamicTableComponentBindings;
  @Input() column  ?: TableColumn;
  @Input() factory  : Type<Component>;
  @Input() index    : number;
  @Input() record   : any;

  subscriptions: Array<Subscription> = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {
    this.loadComponent();
  }

  loadComponent() {
    this.viewContainerRef.clear();

    const componentInstance = this.viewContainerRef.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(this.factory)
    );

    // bind column input.
    if (this.column) {
      componentInstance.instance['column'] = this.column;
    }

    // bind record input.
    componentInstance.instance['record'] = this.record;

    // bind index input.
    componentInstance.instance['index'] = this.index;

    // bind inputs.
    if (this.bindings?.inputs) {
      for (const key of Object.keys(this.bindings.inputs)) {
        componentInstance.instance[key] = this.bindings.inputs[key];
      }
    }

    // subscribe to outputs.
    if (this.bindings?.outputs) {
      for (const key of Object.keys(this.bindings.outputs)) {
        this.subscriptions.push(
          componentInstance.instance[key]
            .subscribe((data: any) => this.bindings.outputs[key](data))
        );
      }
    }
  }
}