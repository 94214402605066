import 'angular';

import { QuickbookStates }          from './quickbooks.states.ajs';
import { QuickbooksActionService }  from './quickbooks.action.service';
import { QuickbooksConnectionCtrl } from './quickbooks.connection';
import { QuickbooksCountryCtrl }    from './quickbooks.country';
import { QuickbooksSignupCtrl }     from './quickbooks.signup';

angular.module('app.quickbooks', [])
  .config(QuickbookStates)
  .controller('QuickbooksConnectionCtrl', QuickbooksConnectionCtrl)
  .controller('QuickbooksCountryCtrl', QuickbooksCountryCtrl)
  .controller('QuickbooksSignupCtrl', QuickbooksSignupCtrl)
  .factory('QuickbooksAction', QuickbooksActionService);
