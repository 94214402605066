import { NgModule } from '@angular/core';

import { AzureSsoService } from './azure-sso.service';

@NgModule({
  providers: [
    AzureSsoService
  ]
})
export class AzureSsoModule {}
