export function CoreEventRun ($rootScope, $transitions) {
  $transitions.onStart({}, () => {
    $rootScope.loaded = false;
  });

  $transitions.onSuccess({}, () => {
    $rootScope.loaded = true;

    document.body.scrollTop = document.documentElement.scrollTop = 0;
  });
}