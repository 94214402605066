import { ChargifyServiceAjs } from './chargify.service.ajs';

function chargifyServiceFactory ( i: any ) {
  return i.get('chargifyApi');
};

export const chargifyServiceProvider = {
  deps      : ['$injector'],
  provide   : ChargifyServiceAjs,
  useFactory: chargifyServiceFactory
};