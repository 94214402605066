import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

import { ApiService }               from '../api/api.service';
import { CompanyIntegrationsModel } from '../companies/models/company.integrations';
import { NetoService }              from './neto.service';
import { NetoSettingsComponent }    from './neto.settings.component';
import { SessionServiceAjs }        from '../sessions/session.service.ajs';

export const NetoStates : Array<NgHybridStateDeclaration> = [
  {
    component: NetoSettingsComponent,
    name     : 'app.neto-settings',
    resolve  : [
      {
        deps     : [ SessionServiceAjs ],
        resolveFn: ( sessionService : SessionServiceAjs ) : ng.IPromise<any> => sessionService.refresh(),
        token    : 'session'
      },
      {
        deps     : [ NetoService ],
        resolveFn: ( netoService : NetoService ) : ng.IPromise<CompanyIntegrationsModel> => netoService.getSettings(),
        token    : 'settings'
      }
    ],
    url: '/neto-settings'
  }
];
