import { Injector, NgModule } from '@angular/core';

import { InvoiceReferenceComponent } from './invoice.reference.component';
import { InvoiceStatusPipe }         from './invoice.status.pipe';
import { InvoiceTypePipe }           from './invoice.type.pipe';

@NgModule({
  declarations: [
    InvoiceReferenceComponent,
    InvoiceStatusPipe,
    InvoiceTypePipe
  ],
  entryComponents: [
    InvoiceReferenceComponent
  ],
  providers: [
    InvoiceStatusPipe,
    InvoiceTypePipe,

    // Pipes must also be provided via string
    // if they are going to be used in a table
    // column. This allows the injector to be
    // able to load a pipe dynamically in the
    // TableCellComponent.
    {
      provide : 'invoiceStatus',
      useClass: InvoiceStatusPipe
    },
    {
      provide : 'invoiceType',
      useClass: InvoiceTypePipe
    },
    {
      deps      : [ '$injector' ],
      provide   : 'Invoice',
      useFactory: ( $injector : Injector ) => $injector.get('Invoice')
    }
  ]
})
export class InvoicesModule {}