import { Component } from '@angular/core';

import { Dictionary            } from '../../../../shared/types/dictionary';
import { TableControlComponent } from '../table-control.component';
import { TableService          } from '../../../table.service';

@Component({
  styles: [ require('../table-control.component.scss') ],
  template: require('./table-select-control.component.html')
})
export class TableSelectControlComponent extends TableControlComponent {
  declare options    : Array<Dictionary>;
  declare placeholder: string;

  constructor (
    service: TableService
  ) {
    super(service);
  }
}