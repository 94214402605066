export function CoreRun ($cookies, $stateParams, $window, jsonAPI, sessionObjAPI, workshopAPI) {
  var session = {};

  if (jsonAPI.getQueryParameters().puppet || $cookies.get('puppetUser')) {
    sessionObjAPI.validate().then(function () {
      return sessionObjAPI.refresh();
    }).then(function (data) {
      session = data;

      if (session.subscription_status === 'canceled' || session.subscription_status === 'trial_ended') {
        $window.location = '/activate.html?puppet=true';
      }

      return workshopAPI.get('/system/user_group_rights');
    }).then(function (rights) {
      sessionObjAPI.setKey('group_rights', rights);

      if (session.company_settings.dealer) {
        $window.location = '/dealer.html?puppet=true';
      }
      else if (session.company_settings.first_login) {
        $window.location = '/workshop.html#/company?puppet=true&first_login=true'
      }
      else if (session.super_user) {
        $window.location = '/workshop.html#/admin_dashboard?puppet=true';
      }
      else {
        $window.location = '/workshop.html?puppet=true';
      }

    }, function (err) {
      sessionObjAPI.clear();
    });
  }
  else if (sessionObjAPI.sessionID() && !$stateParams.disconnect) {
    sessionObjAPI.validate().then(function () {
      return sessionObjAPI.refresh();
    }).then(function () {
      if (sessionObjAPI.company().dealer) {
        $window.location = '/dealer.html#/';
      } else {
        $window.location = '/workshop.html#/';
      }
    });
  }
}