import { NgModule }              from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { NetoService }           from './neto.service';
import { NetoSettingsComponent } from './neto.settings.component';
import { NetoStates }            from './neto.states';
import { SharedModule }          from '../shared/shared.module';

@NgModule({
  declarations: [
    NetoSettingsComponent
  ],
  entryComponents: [
    NetoSettingsComponent
  ],
  imports: [
    SharedModule,

    UIRouterUpgradeModule.forChild({ states: NetoStates })
  ],
  providers: [
    NetoService
  ]
})
export class NetoModule {}