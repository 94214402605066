import { Component, Inject, Input } from '@angular/core';
import { NgbActiveModal }           from '@ng-bootstrap/ng-bootstrap';
import { NgForm }                   from '@angular/forms';

import { ApiService }         from '../api/api.service';
import { Dictionary }         from '../shared/types/dictionary';
import { MessagesServiceAjs } from '../messages/messages.service.ajs';
import { VendorModel }        from './models/vendor';

@Component({
  template: require('./vendor.quick-add.component.html')
})
export class VendorQuickAddComponent {
  private _states : Array<Dictionary>;
  @Input() set states ( value : Array<Dictionary> ) {
    this._states = value;
  }
  get states () : Array<Dictionary> {
    return this._states;
  }

  saving : boolean;
  vendor = {} as VendorModel;

  constructor(
    @Inject('$translate') public $translate : ng.translate.ITranslateService,
    public activeModal                      : NgbActiveModal,
    private apiService                      : ApiService,
    private messagesService                 : MessagesServiceAjs,
  ) {}

  save ( form : NgForm ) : void {
    this.saving = true;

    this.apiService.post('/vendors', this.vendor, 'vendor')
    .then(vendor => {
      this.saving = false;

      this.messagesService.show(this.$translate.instant('JS_SPACE.MESSAGES.SAVED.SAVE_SUCCESSFUL'), 'success');

      this.activeModal.close(vendor);
    });
  }
}