import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'number'
})
export class NumberPipe implements PipeTransform {
  transform ( value : string ) : number {
    const num = parseFloat(value);

    return num;
  }
}