import { Injector, NgModule } from '@angular/core';

import { ConfirmService }     from '../messages/confirm.service.ajs';
import { MessagesServiceAjs } from '../messages/messages.service.ajs';

@NgModule({
  providers: [
    {
      deps      : [ '$injector' ],
      provide   : ConfirmService,
      useFactory: ( $injector : Injector ) => $injector.get('confirm')
    },
    {
      deps      : [ '$injector' ],
      provide   : MessagesServiceAjs,
      useFactory: ( $injector : Injector ) => $injector.get('messages')
    }
  ]
})
export class MessagesModule {}
