export function AuthVerifyCtrl (
  $scope,
  $state,
  $stateParams,
  $translate,
  $urlService,
  loginApi,
  messages,
  workshopAPI
) {
  $scope.customer_id     = $stateParams.customer_id;
  $scope.subscription_id = $stateParams.subscription_id;

  $scope.subscription = {
    country_code: $urlService.config.host().indexOf('au') >= 0 ? 'aus': 'usa',
    email       : '',
    password    : '',
    unlock_code : $stateParams.unlock_code,
    username    : $stateParams.email
  };

  workshopAPI.get('/system/country_types', null, null, {
    bypass: true
  })
  .then(countries => {
    $scope.country_codes = countries;
  });

  $scope.save = function ( form ) {
    workshopAPI.post('/subscriptions/first_user_setup', $scope.subscription)
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.USER_CREATED_CHARGIFY'), 'success');

      loginApi.login($scope.subscription);
    })
    .catch(err => {
      messages.error(err);
    });
  };

  if ($scope.customer_id && $scope.subscription_id) {
    workshopAPI.get(`/subscriptions/verify_chargify_subscription/${$scope.customer_id}/${$scope.subscription_id}`)
    .catch(err => {
      messages.error(err);
    });
  }
}