import { NgModule } from '@angular/core';

import { SharedModule }                       from '../shared/shared.module';
import { TransactionCenterInspectionsModule } from './inspections/transaction-center.inspections.module';
import { TransactionCenterJobsModule }        from './jobs/transaction-center.jobs.module';

@NgModule({
  imports: [
    SharedModule,
    TransactionCenterInspectionsModule,
    TransactionCenterJobsModule
  ]
})
export class TransactionCenterModule {}