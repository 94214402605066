import { NgModule } from '@angular/core';

import { StripeStatusPipe } from './stripe.status.pipe';

@NgModule({
  declarations: [
    StripeStatusPipe
  ],
  providers: [
    StripeStatusPipe
  ]
})
export class StripeModule {}