import { NgModule } from '@angular/core';

import { JobRowExpandComponent } from './transaction-center.job.row.expand.component';
import { JobStatusBookingPipe }  from './transaction-center.jobs.status-booking.pipe';
import { JobStatusPipe }         from './transaction-center.jobs.status.pipe';
import { SharedModule }          from '../../shared/shared.module';

@NgModule({
  declarations: [
    // Components
    JobRowExpandComponent,

    // Pipes
    JobStatusBookingPipe,
    JobStatusPipe
  ],
  entryComponents: [
    JobRowExpandComponent
  ],
  exports: [
    // Components
    JobRowExpandComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    JobStatusBookingPipe,
    JobStatusPipe,

    // Pipes must also be provided via string
    // if they are going to be used in a table
    // column. This allows the injector to be
    // able to load a pipe dynamically in the
    // TableCellComponent.
    {
      provide : 'jobStatusBooking',
      useClass: JobStatusBookingPipe
    },
    {
      provide : 'jobStatus',
      useClass: JobStatusPipe
    }
  ]
})
export class TransactionCenterJobsModule {}
