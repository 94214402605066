import { NgModule }              from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { GsfService }           from './gsf.service';
import { GsfSettingsComponent } from './gsf.settings.component';
import { GsfStates }            from './gsf.states';
import { SharedModule }         from '../shared/shared.module';
import { VendorsModule }        from '../vendors/vendors.module';

@NgModule({
  declarations: [
    GsfSettingsComponent
  ],
  entryComponents: [
    GsfSettingsComponent
  ],
  imports: [
    SharedModule,
    VendorsModule,

    UIRouterUpgradeModule.forChild({ states: GsfStates })
  ],
  providers: [
    GsfService
  ]
})
export class GsfModule {}
