import { ApiServiceAjs }                  from '../api/api.service.ajs';
import { CompanyIntegrationsModel }       from '../companies/models/company.integrations';
import { FlipPayActivationSettingsModel } from './models/flippay.activation-settings';
import { FlipPayOnboardingModel }         from './models/flippay.onboarding';
import { FlipPayPaymentModel }            from './models/flippay.payment';
import { FlipPaySettings }                from './models/flippay.settings';
import { InvoiceModel }                   from '../invoices/models/invoice';
import { SessionServiceAjs }              from '../sessions/session.service.ajs';

import ActivateFlipPayHtml from './flippay.activate.html';
import WindowFlipHtml      from '../core/templates/window-flip.html';
import { FlipPayAccountModel } from './models/flippay.account';

export class FlipPayService {
  $q        : ng.IQService;
  $rootScope: any;
  $sce      : ng.ISCEService;
  $translate: ng.translate.ITranslateService;
  $uibModal : ng.ui.bootstrap.IModalService;
  api       : ApiServiceAjs;
  confirm   : any;
  sessionApi: SessionServiceAjs;

  constructor (
    $q           : ng.IQService,
    $rootScope   : any,
    $sce         : ng.ISCEService,
    $translate   : ng.translate.ITranslateService,
    $uibModal    : ng.ui.bootstrap.IModalService,
    confirm      : any,
    sessionObjAPI: SessionServiceAjs,
    workshopAPI  : ApiServiceAjs
  ) {
    this.$q         = $q;
    this.$rootScope = $rootScope;
    this.$sce       = $sce;
    this.$translate = $translate;
    this.$uibModal  = $uibModal;
    this.api        = workshopAPI;
    this.confirm    = confirm;
    this.sessionApi = sessionObjAPI;
  }

  activate () : ng.IPromise<string | null> {
    let savedSettings : FlipPayActivationSettingsModel;

    return this.$rootScope.Company.hasIntegration('stripe')
      ? this.$q.reject(this.$translate.instant('JS_SPACE.INTEGRATIONS.ACCOUNTING_UNAUTH_FIRST', {
        name1: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.WORKSHOPPAY.WORKSHOPPAY'),
        name2: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.STRIPE.STRIPE')
      }))
      : this.$uibModal.open({
        controller       : 'ActivateFlipPayCtrl',
        controllerAs     : '$ctrl',
        templateUrl      : ActivateFlipPayHtml,
        windowClass      : 'flip-container',
        windowTemplateUrl: WindowFlipHtml
      }).result
    .then(( settings : FlipPayActivationSettingsModel ) => {
      savedSettings = settings;

      return this.api.post('/flippay/activate_flippay', {
        contact_email: settings.notification_email,
        contact_name : settings.contact_name
      });
    })
    .then(( onboarding : FlipPayOnboardingModel ) => savedSettings.hasMerchantId ? null : onboarding.onboardingRequestUrl);
  }

  deactivate () : ng.IPromise<boolean> {
    return this.confirm.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.DEACTIVATE', {
      name: this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.WORKSHOPPAY.WORKSHOPPAY')
    }))
    .then(() => this.api.get('/flippay/deactivate_flippay'))
    .then(() => this.sessionApi.refresh());
  }

  getAccounts () : ng.IPromise<Array<FlipPayAccountModel>> {
    return this.api.get('/flippay/bank_account_list');
  }

  getPaymentLink ( invoice : InvoiceModel, token : string ) : ng.IPromise<string> {
    return this.api.post('/flippay/request_payment', {
      id_token  : token,
      invoice_id: invoice.id
    })
    .then(( response : FlipPayPaymentModel ) => this.$sce.trustAsResourceUrl(response.paymentRequestUrl));
  }

  getSettings () : ng.IPromise<CompanyIntegrationsModel> {
    return this.api.get('/company_integrations');
  }

  isActive ( settings : FlipPaySettings = this.sessionApi.company().flippay_settings ) : boolean {
    return settings.flippay_activation_status === 'A';
  }

  isPending ( settings : FlipPaySettings = this.sessionApi.company().flippay_settings ) : boolean {
    return settings.flippay_activation_status === 'P';
  }

  saveActivationSettings ( settings : FlipPayActivationSettingsModel ) : ng.IPromise<FlipPayActivationSettingsModel> {
    return this.api.post('/flippay/save_settings', settings);
  }

  saveSettings ( settings : CompanyIntegrationsModel ) : ng.IPromise<CompanyIntegrationsModel> {
    return this.api.patch('/company_integrations', settings, 'company_integration');
  }
}