import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgForm }                           from '@angular/forms';
import { UIRouter }                         from '@uirouter/angularjs';

import { CompanyIntegrationsModel } from '../companies/models/company.integrations';
import { GsfService }               from './gsf.service';
import { MessagesServiceAjs }       from '../messages/messages.service.ajs';
import { VendorModel }              from '../vendors/models/vendor';
import { VendorSearchService }      from '../vendors/vendor.search.service';

@Component({
  selector   : 'gsf-settings',
  template: require('./gsf.settings.component.html')
})
export class GsfSettingsComponent implements OnInit {
  @Input() session : any;
  @Input() settings: CompanyIntegrationsModel;

  companyService: any;

  title  : string = this.gsfService.title;
  vendor : VendorModel;

  constructor (
    @Inject('$translate') public $translate : ng.translate.ITranslateService,
    @Inject('Company')           Company    : any,
    private gsfService                      : GsfService,
    private messagesService                 : MessagesServiceAjs,
    private router                          : UIRouter,
    private vendorSearchService             : VendorSearchService
  ) {
    this.companyService = new Company();
  }

  activate () : void {
    this.gsfService.activate()
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATED', {
        name: this.title
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  deactivate () : void {
    this.gsfService.deactivate()
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.MESSAGES.REMOVED_INTEGRATION', {
        name: this.title
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  getVendor () : void {
    this.vendorSearchService.get(this.settings.gsf_car_parts_vendor_id)
    .then(( vendor : VendorModel ) => {
      this.vendor = vendor;
    });
  }

  handleVendorChange ( vendor : string | VendorModel ) {
    if (!vendor) {
      this.settings.gsf_car_parts_vendor_id = '';
    }
  }

  ngOnInit () : void {
    if ( this.settings.gsf_car_parts_vendor_id ) {
      this.getVendor();
    }
  }

  save ( form : NgForm ) : void {
    this.gsfService.save(this.settings)
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.MESSAGES.SAVED.SAVE_SUCCESSFUL'), 'success');

      this.router.stateService.reload();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  setVendorID ( vendor : VendorModel ) : void {
    this.settings.gsf_car_parts_vendor_id = vendor.id;
  }
}
