import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { TextControlComponent } from '../shared/controls/text-control/text-control.component';
import { VendorModel }          from './models/vendor';
import { VendorSearchService }  from './vendor.search.service';

@Component({
  selector   : 'vendor-search-control',
  styles: [ require('../shared/controls/search-control/search-control.component.scss') ],
  template: require('./vendor.search-control.component.html')
})
export class VendorSearchControlComponent extends TextControlComponent {
  @Input() model            : string | VendorModel;
  @Input() placement        : string;
  @Input() resultFormatterFn: Function;

  _allowQuickAdd : boolean;
  @Input() set allowQuickAdd ( _allowQuickAdd : string | boolean ) {
    this._allowQuickAdd = _allowQuickAdd === '' || !!_allowQuickAdd;
  }
  @Output() onSelect   : EventEmitter<any>   = new EventEmitter();

  constructor (
    @Inject('$translate') public $translate : ng.translate.ITranslateService,
    private vendorSearchService             : VendorSearchService
  ) {
    super($translate);
  }

  format = ( vendor : VendorModel ) => {
    return typeof vendor === 'object'
      ? vendor.company_name
      : vendor;
  }

  onSelectChange ( vendor : VendorModel ) {
    this.onSelect.emit(vendor);
  }

  quickAdd () : void {
    this.vendorSearchService.quickAdd()
    .then(vendor => {
      this.model = vendor;

      this.onSelect.emit(vendor);
    })
    .catch(() => null);
  }

  search = ( typed : string ) => {
    return this.vendorSearchService.search(typed);
  }
}