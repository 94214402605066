import 'angular';

import 'angular-animate';
import 'angular-cookies';

import '../../vendor/simpliq-theme/simpliq-theme.module';

import { CoreStates }                  from './core.states.ajs';

import { CoreValidatedFormDirective }  from '../../app/core/directives/core.validated-form.directive';

import { CoreEventRun }                from '../../app/core/core.events';
import { CoreRun }                     from './core.run';

import '../../app-lite/app.module.ajs';

angular.module('app.core', [
  'ngAnimate',
  'ngCookies',
  'simpliq-theme',

  'app-lite'
]).config(CoreStates)
  .directive('validatedForm', CoreValidatedFormDirective)
  .run(CoreEventRun)
  .run(CoreRun);
