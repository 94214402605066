import DefaultVehicleHtml       from './templates/vehicle.default.html';
import MarineVehicleHtml        from './templates/vehicle.marine.html';
import MusicVehicleHtml         from './templates/vehicle.music-instruments.html';
import VehicleTransferOwnerHtml from './vehicle.transfer-owner.html';

export function VehicleLiteCtrl (
  $q,
  $scope,
  $state,
  $translate,
  $uibModal,
  Company,
  confirm,
  messages,
  messengerApi,
  sessionObjAPI,
  vehicleApi,
  vehicleHistoryApi,
  workshopAPI
) {

  $scope.currency            = sessionObjAPI.currency();
  $scope.distanceLabel       = sessionObjAPI.getDistanceLabel();
  $scope.fleetCodeLabel      = $scope.company.company_variable_fields.fleet_code_name || $translate.instant('VEHICLE_SPACE.FLEET_CODE');
  $scope.group               = $scope.vehicle.vehicle_group || ( ($scope.vehicle_body_group_types.length > 0 || !$scope.vehicle.id) ? $scope.vehicle_body_group_types[0].code : '*' );
  $scope.head_office_vehicle = false;
  $scope.isKiwi              = sessionObjAPI.company().country_code === 'nzl';
  $scope.loaded              = true;
  $scope.originalPlateNumber = $scope.vehicle.plate_number;
  $scope.regoLabel           = $scope.company.company_variable_fields.plate_number_name || sessionObjAPI.countrySettings().rego_label;
  $scope.session             = sessionObjAPI.base();
  $scope.vinLabel            = $scope.company.company_variable_fields.vin_name || $translate.instant('VEHICLE_SPACE.VIN');

  $scope.Company   = new Company();

  $scope.checkPlate = function() {
    if ($scope.Company.isLinkedToBranch() && $scope.Company.isDealerMultibranchStore()) {
      return $scope.checkPlateNumberBranch();
    }
    else {
      return $scope.checkPlateNumber();
    }
  };

  $scope.checkPlateNumber = function () {
    if ($scope.vehicle.plate_number && $scope.vehicle.plate_number !== $scope.originalPlateNumber) {
      return workshopAPI.get('/vehicle/verify_plate_uniqueness', $scope.vehicle.plate_number)
      .then(unique => {
        unique = angular.fromJson(unique);

        $scope.form.plate_number.duplicate = !unique;

        return unique;
      });
    }
    else{
      $scope.form.plate_number.duplicate = false;

      return $q.when();
    }

  };

  $scope.checkPlateNumberBranch = function () {
    if ($scope.vehicle.plate_number && $scope.vehicle.plate_number !== $scope.originalPlateNumber) {
      $scope.checkPlateNumber()
        .then(() => $scope.updateFromHeadOffice())
        .then(() => {
          if ($scope.head_office_vehicle) {
            messages.show('Vehicle Details Added and Linked', 'success');
          }
        });
    }
  };

  $scope.delete = function() {
    confirm
      .generic($translate.instant('JS_SPACE.CONFIRM.DELETE_VEHICLE'))
      .then(() => workshopAPI.delete('/vehicle', $scope.vehicle.id))
      .then(() => {
        messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.VEHICLE'), 'success');

        $scope.form.$setPristine();
        $state.go('app.vehicles');
      }).catch(err => messages.error(err));
  };

  $scope.getAdvancedFormTemplateUrl = function () {
    if ($scope.Company.hasAdvancedVehicleFields()) {
      switch ($scope.vehicle.vehicle_group) {
        case 'MARINE':
          return MarineVehicleHtml;
        case 'MUSIC':
          return MusicVehicleHtml;
        default:
          return $scope.defaultVehicleHtml || DefaultVehicleHtml;
      }
    }
    else {
      return $scope.defaultVehicleHtml || DefaultVehicleHtml;
    };
  };

  $scope.getVehicleGroup = function () {
    if ($scope.vehicle.id) {
      workshopAPI
        .get('/vehicles/find_vehicle_group_using_body_type', $scope.vehicle.id)
        .then(bodyTypeGroup => {
          $scope.vehicle.vehicle_group = $scope.vehicle.vehicle_group || (bodyTypeGroup || $scope.vehicle_body_group_types[0].code);

          return $scope.vehicle.vehicle_group;
        }).then(unitVehicleGroup => $scope.updateVehicleBodyGroupType(unitVehicleGroup));
    }
  };

  $scope.initBodyTypes = function () {
    workshopAPI
      .get('/system/vehicle_body_types/', null, 'bodyTypes')
      .then(data => $scope.body_types = data);
  };

  $scope.initDriveTypes = function () {
    workshopAPI
      .get('/system/drive_types/', null, 'driveTypes')
      .then(data => $scope.drive_types = data);
  };

  $scope.initFuelTypes = function () {
    workshopAPI
      .get('/system/fuel_types/', null, 'fuelTypes')
      .then(data => $scope.fuel_types = data);
  };

  $scope.initHullMaterials = function () {
    workshopAPI
      .get('/system/vehicle_hull_materials/', null, 'hullMaterials')
      .then(data => {
        $scope.hull_material_types = data;
      });
  };

  $scope.initHullTypes = function () {
    workshopAPI
      .get('/system/vehicle_hull_types/', null, 'hullTypes')
      .then(data => {
        $scope.hull_types = data;
      });
  };

  $scope.initStates = function () {
    workshopAPI
      .get('/system/state_types', null, 'states')
      .then(states => $scope.states = states);
  };

  $scope.initTransmissionTypes = function () {
    workshopAPI
      .get('/system/transmission_types/', null, 'transmissionTypes')
      .then(data => $scope.transmission_types = data);
  };

  $scope.initialize = function () {
    $scope.initBodyTypes();
    $scope.initHullMaterials();
    $scope.initHullTypes();
    $scope.initTransmissionTypes();
    $scope.initFuelTypes();
    $scope.initDriveTypes();
    $scope.initStates();
    $scope.getVehicleGroup();
    $scope.updateVehicleBodyGroupType($scope.vehicle.vehicle_group);
  };

  $scope.isUnit = function () {
    return vehicleApi.isUnit($scope.vehicle);
  };

  $scope.isLinkedToHeadOffice = function () {
    return vehicleApi.isLinkedToHeadOffice($scope.vehicle);
  };

  $scope.save = function () {
    const method = $scope.vehicle.id ? 'patch': 'post';
    const url    = $scope.vehicle.id ? '/vehicle': '/vehicles';

    $scope.checkPlateNumber()
    .then(() => $scope.form.plate_number.duplicate ?
      confirm.generic($translate.instant('JS_SPACE.MESSAGES.VEHICLE_ALREADY_EXISTS', {
        label: $scope.regoLabel ,
        rego : $scope.vehicle.plate_number
      })) : $q.when()
    )
    .then(() => workshopAPI[method](url, $scope.vehicle, 'vehicle'))
    .then(vehicle => {
      $scope.vehicle = vehicle;

      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.VEHICLE'), 'success');
      $scope.form.$setPristine();
      $state.go('app.vehicle', {
        customer_id: vehicle.customer_id,
        id         : vehicle.id
      });
    })
    .catch(err => messages.error(err));
  };

  $scope.sendBookingRequest = function () {
    const url = `${sessionObjAPI.base().origin}bookings.html#/${$scope.company.company_settings.public_booking_diary_company_url}?token=${$scope.company.company_settings.public_booking_diary_token}&customer_id=${$scope.customer.id}&vehicle_id=${$scope.vehicle.id}`;

    messengerApi.email({
      body         : `<a class="color-blue pointer" href="${url}" target="_blank">Click here</a> to schedule your service!`,
      email_address: $scope.customer.email,
      name         : $scope.customer.display_name,
      subject      : 'Book Your Vehicle for Service'
    }).then(() => messages.show('Email Sent', 'success'))
    .catch(err => messages.error(err));
  };

  $scope.syncWithHeadOffice = function () {
    $q.when()
    .then(() => $scope.form.$dirty ? $scope.Vehicle.save() : $q.when())
    .then(() => workshopAPI.patch('/vehicles/sync_multibranch_vehicle_entry', $scope.vehicle))
    .then(() => {
      messages.show($translate.instant('MULTI_BRANCH_SPACE.VEHICLE_LINKED'), 'success');

      $scope.form.$setPristine();

      $state.reload();
    })
    .catch(err => messages.error(err));
  };

  $scope.transferOwner = function () {
    $uibModal.open({
      controller : 'VehicleTransferOwnerLiteCtrl',
      templateUrl: VehicleTransferOwnerHtml,
      windowClass: 'lg-modal',
      resolve    : {
        customer : () => $scope.customer,
        customers: () => workshopAPI.get('/customers', [0, 5, 'display_name', 'asc']),
        vehicle  : () => $scope.vehicle
      }
    }).result.then(vehicle => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.VEHICLE_OWNERSHIP_TRANSFERRED'), 'success');

      $state.go('app.vehicle', {
        customer_id: vehicle.customer_id,
        id         : vehicle.id
      });
    });
  };

  $scope.updateFromHeadOffice = function () {
    return workshopAPI
      .post('/vehicles/retrieve_from_dealer_branch', $scope.vehicle, 'vehicle')
      .then(result => {
        $scope.dealer_vehicle = result;

        return result && result !== 'null' ?
          confirm.generic('This vehicle exists in head office. Would you like to use these details?') : $q.when();
      }).then(() => {
        if ($scope.dealer_vehicle !== 'null') {
          $scope.head_office_vehicle = true;
          $scope.vehicle             = {
            ...$scope.dealer_vehicle,
            id: $scope.vehicle.id || $scope.dealer_vehicle.id
          };
        }

        return $q.when(true);
      });
  };

  $scope.updateVehicleBodyGroupType = function (group) {
    const type = group || $scope.group;

    return type ?
      workshopAPI
        .get('/system/vehicle_body_types_limited', type)
        .then(data => $scope.body_types_limited = data)
      : $q.when(true);
  };

  $scope.vehicleHistory = function() {
    vehicleHistoryApi.view($scope.vehicle);
  };

  $scope.vehicleHistoryReport = function() {
    vehicleHistoryApi.viewReport($scope.vehicle);
  };

  $scope.viewUnit = function () {
    $state.go('app.unit', {
      id: $scope.vehicle.id
    });
  };

  $scope.initialize();
};