/*
 * Used on forms to ensure proper validation on them.
 * wraps around the form submit and prevents it if the
 * form isn't valid. Also, whenever a route change is
 * called, this directive checks if the form is dirty
 * and if it is provides a warning before navigating.
 */
export function CoreValidatedFormDirective (
  $parse,
  $rootScope,
  $state,
  $transitions,
  $translate,
  messages,
  confirm
) {
  return {
    require : 'form',
    restrict: 'A',
    link    (scope, element, attrs, ctrl) {
      // Get the submit function
      const fn = $parse(attrs.validatedForm);

      element.bind('submit', evt => {

        /*
        If the form is not valid, display
        the errors and cancel the submit
        */
        if (!ctrl.$valid) {
          scope.$apply(() => {
            messages.clientError(ctrl);
          });
          return;
        }

        scope.$apply(() => {
          fn(scope, { $event: evt });
        });
      });

      /**
       * checks to see if the form is dirty and if the flag is set.
       * If both are the case then provide a warning and prevent the
       * default route change. then either reset the flag or change the
       * route after the confirm is resolved.
       */
      $transitions.onStart({}, transition => {
        if (ctrl.$dirty && !$rootScope.changeToRoute) {
          confirm
            .generic($translate.instant('JS_SPACE.CONFIRM.UNSAVED_DATA'))
            .then(() => {
              $state.go(transition.to().name, transition.params(), {
                inherit: false
              });

              ctrl.$setPristine();

              $rootScope.changeToRoute = true;
            })
            .catch(() => $rootScope.changeToRoute = false);

          return false;
        }
      });
    }
  };
}