export function QuickbooksConnectionCtrl ($scope, $state, $stateParams, $window, messages, QuickbooksAction) {
  $scope.quickbooksAction = new QuickbooksAction($stateParams);

  if ($scope.quickbooksAction.isLoginReady()) {
    $scope.quickbooksAction.login()
    .then(url => {
      $window.location.assign(url);
    })
    .catch(err => {
      messages.error(err);
    });
  }

  else {
    $scope.quickbooksAction.launchSSO()
    .then(url => {
      $window.location.assign(url);
    })
    .catch(err => {
      messages.error(err);
    });
  }
}
