export function ResetPasswordCtrl ($state, $stateParams, $scope, $translate, messages, workshopAPI){
  
  $scope.user = {
    id: $stateParams.id,
    reset_token: $stateParams.reset_token,
    password: ''
  };
  
  $scope.resetPassword = function (form) {
    workshopAPI.post('/users/reset_password', $scope.user, null, null, {
      bypass: true
    }).then(function () {
      $state.go('app.login');
      form.$setPristine();
      messages.show($translate.instant('JS_SPACE.MESSAGES.PASSWORD_RESET'), 'success');
    }).catch(function (err) {
      messages.error(err);
    });
  };
  
  $scope.sendEmail = function (form) {
    workshopAPI.post('/users/request_reset_email', $scope.user.email, 'email').then(function () {
      $state.go('app.login');
      form.$setPristine();
      messages.show($translate.instant('JS_SPACE.MESSAGES.EMAIL_HAS_BEEN_SENT'), 'success');
    }).catch(function (err) {
      messages.error(err);
    });
  }
}