export function LoginCtrl ($scope, $window, azureSsoApi, loginApi, messages) {
  $scope.login = function () {
    loginApi.login($scope.user)
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.loginWithAzure = function () {
    azureSsoApi.getLoginUrl()
    .then(url => {
      $window.location.assign(url);
    })
    .catch(err => {
      messages.error(err);
    });
  };
}
