export function SignUpCtrl (
	$http,
	$scope,
	$state,
	$translate,
	countries,
	messages
) {
	$scope.countries = countries.data;

	$scope.user = {
		unlock_code: '',
		capricorn_id: '',
		company_name: '',
		country: 'aus',
		email: ''
	};

	$scope.signUp = function () {
		$http.post('/subscriptions/capricorn_setup', $scope.user).then(() => {
			messages.show($translate.instant('JS_SPACE.MESSAGES.CREATED.USER'), 'success');

			$state.go('app.login');
		}).catch(data => {
			messages.error(data);
		});
	};
};