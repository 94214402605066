import { Injector, NgModule  } from '@angular/core';

import { MessagesServiceAjs }           from '../messages/messages.service.ajs';
import { SharedModule }                 from '../../app-lite/shared/shared.module';
import { VendorActionsComponent }       from './vendor.actions.component';
import { VendorQuickAddComponent }      from './vendor.quick-add.component';
import { VendorSearchControlComponent } from './vendor.search-control.component';
import { VendorSearchService }          from './vendor.search.service';

@NgModule({
  declarations: [
    VendorActionsComponent,
    VendorQuickAddComponent,
    VendorSearchControlComponent
  ],
  entryComponents: [
    VendorActionsComponent,
    VendorQuickAddComponent,
    VendorSearchControlComponent
  ],
  exports: [
    VendorSearchControlComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    VendorSearchService
  ]
})
export class VendorsModule {}