import { NgModule }                                 from '@angular/core';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { ApiModule }          from '../api/api.module';
import { RecpatchaService }   from './recaptcha.service';
import { RECAPTCHA_SETTINGS } from './recpatcha.constant';

@NgModule({
  imports: [
    RecaptchaV3Module,
    ApiModule
  ],
  providers: [
    RecpatchaService,
    {
      provide : RECAPTCHA_V3_SITE_KEY,
      useValue: RECAPTCHA_SETTINGS.siteKey
    }
  ]
})
export class RecpatchaModule {}
