export function QuickbooksSignupCtrl ($scope, $state, $stateParams, $window, countries, messages, QuickbooksAction) {
  $scope.countries        = countries;
  $scope.quickbooksAction = new QuickbooksAction($stateParams);
  $scope.user             = {};

  $scope.submit = function () {
    $scope.quickbooksAction.signup($scope.user)
    .then(() => $scope.quickbooksAction.launchSSO($scope.user))
    .then(() => $scope.quickbooksAction.login($scope.user))
    .then(url => {
      $window.location.assign(url);
    })
    .catch(err => {
      messages.error(err);
    });
	};
}
