import { NgModule  } from '@angular/core';
import { DndModule } from 'ngx-drag-drop';

import { ApiModule                       } from '../api/api.module';
import { BindHtmlComponent               } from './cell-components/bind-html.component';
import { DynamicTableComponentComponent  } from './dynamic-table-component.component';
import { EditComponent                   } from './cell-components/actions/edit.component';
import { LinkComponent                   } from './cell-components/actions/link.component';
import { PipesModule }                     from './pipes/pipes.module';
import { PrintComponent                  } from './cell-components/actions/print.component';
import { RemoveComponent                 } from './cell-components/actions/remove.component';
import { SharedModule                    } from '../shared/shared.module';
import { TableCellComponent              } from './table-cell.component';
import { TableComponent                  } from './table.component';
import { TableDateControlComponent       } from './cell-components/controls/date-control/table-date-control.component';
import { TablePartSearchControlComponent } from './cell-components/controls/typeahead-control/table-part-search-control.component';
import { TableSelectControlComponent     } from './cell-components/controls/select-control/table-select-control.component';
import { TableService                    } from './table.service';
import { TableSwitchControlComponent     } from './cell-components/controls/switch-control/table-switch-control.component';
import { TableTextControlComponent       } from './cell-components/controls/text-control/table-text-control.component';

@NgModule({
  declarations: [

    // Components.
    DynamicTableComponentComponent,
    TableCellComponent,
    TableComponent,

    // Action components.
    BindHtmlComponent,
    EditComponent,
    LinkComponent,
    PrintComponent,
    RemoveComponent,

    // Control components.
    TableDateControlComponent,
    TablePartSearchControlComponent,
    TableSelectControlComponent,
    TableSwitchControlComponent,
    TableTextControlComponent
  ],
  entryComponents: [

    // Components.
    TableComponent,

    // Action components.
    BindHtmlComponent,
    EditComponent,
    LinkComponent,
    PrintComponent,
    RemoveComponent,

    // Control components.
    TableDateControlComponent,
    TablePartSearchControlComponent,
    TableSelectControlComponent,
    TableSwitchControlComponent,
    TableTextControlComponent
  ],
  exports: [

    // Components.
    DynamicTableComponentComponent,
    TableCellComponent,
    TableComponent,

    // Action components.
    EditComponent,
    PrintComponent,
    RemoveComponent,

    // Control components.
    TableDateControlComponent,
    TablePartSearchControlComponent,
    TableSelectControlComponent,
    TableSwitchControlComponent,
    TableTextControlComponent
  ],
  imports: [
    // Vendor modules.
    DndModule,

    // Feature modules.
    ApiModule,
    SharedModule,

    // Table child modules
    PipesModule
  ],
  providers: [
    TableService
  ]
})
export class TableModule {}
