import { NgModule } from '@angular/core';

import { CompanyTypeComponent } from './company.company-type.component'

@NgModule({
    declarations: [
      CompanyTypeComponent
    ],
    entryComponents: [
      CompanyTypeComponent
    ],
    exports: [
      CompanyTypeComponent
    ]
  })
  export class CompaniesModule {}