import { Component, Inject, Input, OnInit } from '@angular/core';
import { UIRouter }                         from '@uirouter/angularjs';

import { CompanyIntegrationsModel } from '../companies/models/company.integrations';
import { MessagesServiceAjs }       from '../messages/messages.service.ajs';
import { NetoService }              from './neto.service';
import { NetoWarehouseModel }       from './models/neto.warehouse';

@Component({
  selector   : 'neto-settings',
  template: require('./neto.settings.component.html')
})
export class NetoSettingsComponent implements OnInit {
  @Input() session : any;
  @Input() settings: CompanyIntegrationsModel;

  companyService : any;

  warehouses = [] as Array<NetoWarehouseModel>;

  constructor (
    @Inject('$translate')
    public $translate       : ng.translate.ITranslateService,
    @Inject('Company')
    Company                 : any,
    private netoService     : NetoService,
    private messagesService : MessagesServiceAjs,
    private router          : UIRouter,
  ) {
    this.companyService = new Company();
  }

  activate () : void {
    this.netoService.activate()
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATED', {
        name: 'Neto'
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(( err : any ) => {
      this.messagesService.error(err);
    });
  }

  deactivate () : void {
    this.netoService.deactivate()
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.INTEGRATIONS.DEACTIVATED', {
        name: 'Neto'
      }), 'success');

      this.router.stateService.reload();
    })
    .catch(( err : any ) => {
      this.messagesService.error(err);
    });
  }

  getAuthorizeUrl () : string {
    return this.netoService.getAuthorizeUrl();
  }

  hasStoreDomain () : boolean {
    return this.netoService.hasStoreDomain();
  }

  ngOnInit(): void {
    if ( this.companyService.hasIntegration('neto') && this.hasStoreDomain() ) {
      this.netoService.getWarehouses()
      .then(( warehouses : Array<NetoWarehouseModel> ) => {
        this.warehouses = warehouses;
      })
    }
  }

  save () : void {
    this.netoService.saveSettings(this.settings)
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.MESSAGES.SAVED.SETTINGS'), 'success');

      this.router.stateService.reload();
    })
    .catch(( err : any ) => {
      this.messagesService.error(err);
    });
  }
}