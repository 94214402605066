import { NgModule } from '@angular/core';

import { PropertyPipe } from './property.pipe';

@NgModule({
  declarations: [
    PropertyPipe
  ],
  providers: [
    PropertyPipe,

    // Pipes must also be provided via string
    // if they are going to be used in a table
    // column. This allows the injector to be
    // able to load a pipe dynamically in the
    // TableCellComponent.
    {
      provide : 'propertyPipe',
      useClass: PropertyPipe
    }
  ]
})
export class PipesModule {}
