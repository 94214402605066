import { NgModule } from '@angular/core';

import { chargifyServiceProvider } from './chargify.provider.ajs';
import { ChargifyService }         from './chargify.service';

@NgModule({
  providers: [
    // AJS upgraded service
    chargifyServiceProvider,

    ChargifyService
  ]
})
export class ChargifyModule {}
