export function QuickbooksActionService ($q, $translate, API, sessionObjAPI, workshopAPI) {
  const QuickbooksAction = function (parameters = {}) {
    this.$q         = $q;
    this.$translate = $translate;

    this.API           = API;
    this.sessionObjAPI = sessionObjAPI;
    this.workshopAPI   = workshopAPI;

    this.parameters = parameters;
  };

  QuickbooksAction.prototype.getSignupUrlFromCountry = function(country) {
    const self = this;
    const tag  = '/login.html#/quickbooks-signup';

    switch (country) {
      case 'can':
        return $q.when(`${ self.API.domains.can }${tag}&country=${country}`);
      case 'gbr':
        return $q.when(`${ self.API.domains.gbr }${tag}`);
      case 'usa':
        return $q.when(`${ self.API.domains.usa }${tag}`);
      default:
        return $q.when(`${ self.API.domains.aus }${tag}`);
    }
  };

  QuickbooksAction.prototype.getTitle = function () {
    const self = this;

    if (self.isDisconnecting()) {
      return 'GENERAL_SPACE.MESSAGE.NOW_DISCONNECTING_YOU';
    }

    if (self.isLoginReady()) {
      return 'GENERAL_SPACE.MESSAGE.LOGGING_YOU_IN';
    }

    return 'GENERAL_SPACE.MESSAGE.NOW_CONNECTING_YOU';
  };

  QuickbooksAction.prototype.isDisconnecting = function () {
    const self = this;

    return self.parameters.disconnect === 'true';
  };

  QuickbooksAction.prototype.isLoginReady = function () {
    const self = this;

    return self.parameters.qbo_login === 'true' &&
      self.parameters.qbo_sso_token;
  };

  QuickbooksAction.prototype.launchSSO = function (user = {}) {
    const self     = this;
    const settings = user.username ? {} : {
      params: {
        disconnect: self.isDisconnecting()
      }
    };

    return self.workshopAPI.get('/quickbooks_online/authenticate', null, null, settings).then(response => response.url);
  };

  QuickbooksAction.prototype.login = function (user = {}) {
    const self = this;

    let session;

    return self.$q.when().then(() => {
      return user.username ?
        workshopAPI.post('/sessions/workshop', user) :
        self.workshopAPI.post('/sessions/qbo', self.parameters);
    }).then(response => {
      session = response;

      self.sessionObjAPI.setCookie(session.session_id);
      self.sessionObjAPI.setKey('session_object', session);

      if (session.subscription_status === 'canceled' || session.subscription_status === 'trial_ended') {
        return self.$q.when('/activate.html');
      }

      return self.workshopAPI.get('/system/user_group_rights');
    }).then(rights =>  {
      self.sessionObjAPI.setKey('group_rights', rights);

      return self.isDisconnecting() ? workshopAPI.get('/company/remove_qbo/') : self.$q.when(true);
    }).then(() => {
      if (self.isDisconnecting()) {
        return self.$q.when('/workshop.html#/quickbooks_settings');
      }
      else if (session.super_user) {
        return self.$q.when('/workshop.html#/admin_dashboard');
      }

      return self.$q.when('/workshop.html#/dashboard');
    });
  };

  QuickbooksAction.prototype.signup = function (user = {}) {
    const self = this;

    return self.workshopAPI.post('/subscriptions/quickbooks_setup', user);
  };

  return QuickbooksAction;
}
