import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyPipe'
})
export class PropertyPipe implements PipeTransform {

  transform (value: string, entity = {}): any {
    return value
      ?.split('.')
      .reduce((prev, curr) => prev ? prev[curr] : null, entity);
  }
}
