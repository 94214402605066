import { Injectable } from '@angular/core';
import { NgbModal }   from '@ng-bootstrap/ng-bootstrap';

import { ApiService }              from '../api/api.service';
import { Dictionary }              from '../shared/types/dictionary';
import { VendorModel }             from './models/vendor';
import { VendorQuickAddComponent } from './vendor.quick-add.component';

@Injectable({
  providedIn: 'root'
})
export class VendorSearchService {
  constructor (
    private apiService   : ApiService,
    private modalService : NgbModal
  ) {}

  get ( id : string ) : ng.IPromise<VendorModel> {
    return this.apiService.get('/vendor', id);
  }

  getStates () : ng.IPromise<Array<Dictionary>> {
    return this.apiService.get('/system/state_types', null, 'states');
  }

  quickAdd () : ng.IPromise<VendorModel> {
    const modalRef = this.modalService.open(VendorQuickAddComponent);

    this.getStates()
    .then(states => {
      modalRef.componentInstance.states = states;
    });

    return modalRef.result;
  }

  search ( query : string ) : ng.IPromise<Array<VendorModel>> {
    return this.apiService.stream('/searches', [ query, 'vendors' ]);
  }
}