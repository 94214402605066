import { NgModule } from '@angular/core';

import { BillerLookupComponent } from './biller.lookup.component';
import { BillerService }         from './biller.service';
import { SharedModule }          from '../shared/shared.module';
import { TableModule }           from '../table/table.module';

@NgModule({
  declarations: [
    BillerLookupComponent
  ],
  entryComponents: [
    BillerLookupComponent
  ],
  exports: [
    BillerLookupComponent
  ],
  imports: [
    SharedModule,
    TableModule
  ],
  providers: [
    BillerService
  ]
})
export class BillerModule {}