import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

import { ApiService }               from '../api/api.service';
import { CompanyIntegrationsModel } from '../companies/models/company.integrations';
import { GsfSettingsComponent }     from './gsf.settings.component';
import { SessionServiceAjs }        from '../sessions/session.service.ajs';

export const GsfStates : Array<NgHybridStateDeclaration> = [
  {
    component: GsfSettingsComponent,
    name     : 'app.gsf-settings',
    resolve  : [
      {
        deps     : [ SessionServiceAjs ],
        resolveFn: ( sessionService : SessionServiceAjs ) : ng.IPromise<any> => sessionService.refresh(),
        token    : 'session'
      },
      {
        deps     : [ ApiService ],
        resolveFn: ( apiService : ApiService ) : ng.IPromise<CompanyIntegrationsModel> => apiService.get('/company_integrations'),
        token    : 'settings'
      }
    ],
    url: '/gsf-settings'
  }
];