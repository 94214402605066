import { ApiServiceAjs }              from '../../app-lite/api/api.service.ajs';
import { AzureSsoService }            from '../../app-lite/azure-sso/azure-sso.service';
import { SessionServiceAjs }          from '../../app-lite/sessions/session.service.ajs';
import { TwoFactorAuthSettingsModel } from '../two-factor-auth/models/two-factor-auth.settings';
import { UserModel }                  from '../../app-lite/users/models/user';

export class LoginServiceAjs {
  constructor (
    private $q           : ng.IQService,
    private $state       : ng.ui.IStateService,
    private $window      : ng.IWindowService,
    private azureSsoApi  : AzureSsoService,
    private sessionObjAPI: SessionServiceAjs,
    private workshopAPI  : ApiServiceAjs
  ) {}

  login ( user : UserModel ) : ng.IPromise<any> {
    return this.workshopAPI.post('/sessions/workshop', user, null, null, {
      verbose: true
    })
    .catch(( response : ng.IHttpResponse<any> ) => {
      return this.azureSsoApi.isSsoRequiredForLogin(response)
        ? this.azureSsoApi.getSessionUrl(user)
          .then(url => {
            this.$window.location.assign(url);

            return this.$q.reject(response.data);
          })
        : this.$q.reject(response.data);
    })
    .then(( response : ng.IHttpResponse<any> ) => response.data)
    .then(( session : any ) => this.handleSession(session, user));
  }

  loginWithTwoFactorAuth ( settings : TwoFactorAuthSettingsModel ) : ng.IPromise<any> {
    return this.workshopAPI.post('/sessions/submit_otp', settings, null, null, {
      verbose: true
    })
    .then(( response : ng.IHttpResponse<any> ) => response.data)
    .then(( session : any ) => this.handleSession(session));
  }

  handleSession ( session : any, user : UserModel = { username: session.username } ) : ng.IPromise<any> {
    if ( session.two_factor_auth_required ) {
      this.sessionObjAPI.setKey('user', {
        id: session.user_id,
        ...user
      });

      this.$state.go('app.two-factor-auth-login');

      return;
    }

    return this.sessionObjAPI.handleSessionLogin(session, user);
  }
}