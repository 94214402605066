import { Component } from '@angular/core';

import { TableControlComponent } from '../table-control.component';
import { TableService          } from '../../../table.service';

@Component({
  styles: [ require('../table-control.component.scss'), require('./table-date-control.component.scss') ],
  template: require('./table-date-control.component.html')
})
export class TableDateControlComponent extends TableControlComponent {
  constructor (
    service: TableService
  ) {
    super(service);
  }
}