import { BrowserModule } from '@angular/platform-browser';
import { NgModule }      from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';

import { AppLiteModule } from '../app-lite/app.module';

@NgModule({
  imports: [
    BrowserModule,
    UpgradeModule,
    AppLiteModule
  ]
})
export class AppModule {
  constructor( private upgrade: UpgradeModule ) { }

  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, ['app'], { strictDi: false });
  }
}
