import { NgModule } from '@angular/core';

import { TransactionCenterInspectionsService } from './transaction-center.inspections.service';

@NgModule({
  providers: [
    TransactionCenterInspectionsService
  ]
})
export class TransactionCenterInspectionsModule {}
